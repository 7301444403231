<template>
  <div class="interactions">
    <input
      type="text"
      :value="chartOptions"
      style="width:0px;height:0px;opacity:0;"
    />
    <highcharts v-if="view" :options="chartOptions"> </highcharts>
    <div
      v-else    
    >
      <img
        class="opacity-50"
        src="@/assets/image-off-interactionsbenchmark.png"
        alt=""
      />
      <div class="image-container-off__message">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOffGraphic }}
      </div>
    </div>
    <div class="tab-interactions-container-separator">
      <div data-s-9="">
        <table class="interactions-page-table-style">
          <thead>
            <!-- <tr> -->
            <th class="header-table" style="text-align: left;">Page</th>
            <th
              v-if="optrss == 1 || optrss == 3 || optrss == 4 || optrss == 5"
              class="header-table"
            >
              Comments
            </th>
            <th
              v-if="optrss == 1 || optrss == 3 || optrss == 4 || optrss == 5"
              class="header-table"
            >
              Likes
            </th>
            <th v-if="optrss == 4" class="header-table">Videos</th>
            <th v-if="optrss == 4" class="header-table">Subscribers</th>
            <th v-if="optrss == 4" class="header-table">News Subscribers</th>
            <th v-if="optrss == 1" class="header-table">Shares</th>
            <!-- <th
              v-if="optrss == 1 || optrss == 3 || optrss == 5"
              class="header-table"
            >
              Post
            </th> -->
            <th v-if="optrss == 1" class="header-table">Page fans</th>
            <th v-if="optrss == 1" class="header-table">Nuevos fans</th>

            <th v-if="optrss == 2" class="header-table">Retweets</th>
            <th v-if="optrss == 2" class="header-table">Favorites</th>
            <th v-if="optrss == 2" class="header-table">Tweets</th>
            <th
              v-if="optrss == 2 || optrss == 3 || optrss == 5"
              class="header-table"
            >
              Followers
            </th>
            <th
              v-if="optrss == 2 || optrss == 3 || optrss == 5"
              class="header-table"
            >
              News Followers
            </th>
            <!-- </tr>  -->
          </thead>
          <tbody v-for="(item, key) in followers" :key="item + key">
            <!-- <tr  > -->
            <td class="name-page-table tableinteractions">
              <p
                class="openperfil"
                @click="abrirpestanias(item.dscUrlRedSocial)"
              >
                <b-img
                  thumbnail
                  fluid
                  v-bind:src="item.dscLogo"
                  class="img-thumbnail img-fluid"
                  width="50"
                  height="50"
                  @error="errorUrlImage"
                />
                {{ item.nomUsuarioRedsocial }}
              </p>
            </td>
            <td
              v-if="optrss == 2"
              class="content-items-table tableinteractions"
            >
              {{ addCharacter(item.retweets) }}
            </td>
            <td
              v-if="optrss != 2"
              class="content-items-table tableinteractions"
            >
              {{
                item.totalcomments == 0
                  ? addCharacter(item.comments)
                  : addCharacter(item.totalcomments)
              }}
            </td>
            <td class="content-items-table tableinteractions">
              {{ addCharacter(item.likes) }}
            </td>
            <td
              v-if="optrss == 1"
              class="content-items-table tableinteractions"
            >
              {{ addCharacter(item.shares) }}
            </td>
            <td  v-if="optrss == 4" class="content-items-table tableinteractions">
              {{ addCharacter(item.countVideos) }}
            </td>
            <td class="content-items-table tableinteractions">
              {{ addCharacter(item.audiencia_total) }}
            </td>
            <td class="content-items-table tableinteractions">
              {{ addCharacter(item.audiencia_nueva) }}
            </td>
            <!-- </tr> -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import stockInit from "highcharts/modules/stock";
import { Helpers, mapGetters } from "@isc/styleguide";

stockInit(Highcharts);
exportingInit(Highcharts);

export default {
  name: "interactions",
  props: {
    followers: Array,
    optrss: Number,
  },

  components: {
    highcharts: Chart,
  },

  data() {
    return {
      view: false,
    };
  },
  computed: {
    ...mapGetters({
      msgImgOffGraphic: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
    chartOptions: function() {
      let like = [];
      let comments = [];
      let shares = [];
      let nombreUsuario = [];
      let dscUrlRedSocial = [];
      let retweets = [];

      for (const item of this.followers) {
        like.push(item.likes);
        comments.push(
          item.totalcomments == 0 ? item.comments : item.totalcomments
        );
        shares.push(item.shares);
        nombreUsuario.push(item.nomUsuarioRedsocial);
        dscUrlRedSocial.push(item.dscUrlRedSocial);
        retweets.push(item.retweets);
      }
      let seriesfb = [
        {
          name: "Likes",
          data: like,
          color: "rgb(71, 119, 238)",
        },
        {
          name: "Comments",
          data: comments,
          color: "rgb(123, 162, 239)",
        },
        {
          name: "Shares",
          data: shares,
          color: "rgb(188, 207, 239)",
        },
      ];
      let seriesyt = [
        {
          name: "Likes",
          data: like,
          color: "rgb(255, 101, 100)",
        },
        {
          name: "Comments",
          data: comments,
          color: "rgba(255, 101, 100, 0.3)",
        },
      ];
      let seriestw = [
        {
          name: "Favorites",
          data: like,
          color: "rgb(24, 119, 242)",
        },
        {
          name: "Retweets",
          data: retweets,
          color: "rgba(24, 119, 242, 0.3)",
        },
      ];
      let seriesig = [
        {
          name: "Likes",
          data: like,
          color: "rgb(255, 105, 161)",
        },
        {
          name: "Comments",
          data: comments,
          color: "rgba(255, 105, 161, 0.3)",
        },
      ];
      let seriesli = [
        {
          name: "Likes",
          data: like,
          color: "rgb(71, 119, 238)",
        },
        {
          name: "Comments",
          data: comments,
          color: "rgb(123, 162, 239)",
        },
      ];
      let chartOption = {
        chart: {
          type: "bar",
          innerHeight: 120,
        },

        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: nombreUsuario,
        },
        yAxis: {
          min: 0,
          allowDecimals: false,
          title: {
            text: "",
          },
        },
        legend: {
          reversed: true,
          align: "right",
        },
        plotOptions: {
          series: {
            stacking: "normal",
          },
        },
        series:
          this.optrss == 1
            ? seriesfb
            : this.optrss == 2
            ? seriestw
            : this.optrss == 3
            ? seriesig
            : this.optrss == 4
            ? seriesyt
            : this.optrss == 5
            ? seriesli
            : [],
      };
      this.Validate(chartOption.series);

      return chartOption;
    },
  },
  methods: {
    abrirpestanias(url) {
      var win = (objeto_window_referencia = window.open(url, "_blank"));
      win.focus();
    },
    Validate(series) {
      this.view = false;
      for (const item of series) {
        if (!this.view) {
          this.view = item.data.some((it) => {
            if (it > 0) {
              return true;
            }
          });
        }
      }
    },
    addCharacter(value) {
      return Helpers.addCharacter(value);
    },
    errorUrlImage(event){
        event.target.src = require("../../assets/off-image_170x170.png");
    }
  },
  mounted() {},
};
</script>
<style scoped>
.tab-interactions-container-separator {
  padding: 2em 0;
}
[data-s-9] .interactions-page-table-style,
.interactions-page-table-style[data-s-9] {
  width: 100%;
}

[data-s-9] .interactions-page-table-style,
.interactions-page-table-style[data-s-9] {
  width: 100%;
}
* {
  box-sizing: border-box;
  font-family: "Oxigen", sans-serif;
}

table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
}
.header-table {
  font-size: 14px;
  font-weight: bold;
  color: #333b52;
  text-align: center;
}
/* [data-s-9] .header-table, .header-table[data-s-9] {
    font-size: 12px;
    font-weight: bold;
    color: rgb(51, 59, 82);
    text-align: center;
} */
th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: -internal-center;
}
table {
  border-collapse: separate;
  text-indent: initial;
  border-spacing: 2px;
}
body {
  font-family: "Oxigen", sans-serif;
  margin: 0;
  padding: 0;
}

[data-s-9] .name-page-table,
.name-page-table[data-s-9] {
  font-size: 12px;
  text-align: left;
  color: rgb(92, 163, 252);
  text-decoration: none;
  display: flex;
  align-items: center;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
table[data-v-16ef720a] {
  border-collapse: separate;
  text-indent: initial;
  border-spacing: 2px;
}
[data-s-9] .name-page-table[data-v-16ef720a],
.name-page-table[data-s-9][data-v-16ef720a] {
  font-size: 12px;
  text-align: left;
  color: rgb(92, 163, 252);
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
[data-s-9] .url-page-table,
.url-page-table[data-s-9] {
  color: rgb(92, 163, 252);
  margin-left: 5px;
  cursor: pointer;
}
.centrar-texto {
  text-align: center;
}
[data-s-9] .content-items-table,
.content-items-table[data-s-9] {
  font-family: Oxygen;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
  text-align: center;
  color: rgb(97, 107, 134);
}
.openperfil {
  cursor: pointer;
}
.interactions [data-s-9] .content-items-table[data-v-16ef720a],
.content-items-table[data-s-9][data-v-16ef720a] {
  font-family: Oxygen !important;
  font-size: 12px !important;
  font-weight: normal !important;
  line-height: 1.5 !important;
  text-align: center !important;
  color: rgb(97, 107, 134) !important;
}
/* .interactions [data-s-9] .header-table[data-v-16ef720a], .header-table[data-s-9][data-v-16ef720a] {
    font-size: 12px !important;
    font-weight: bold !important;
    text-align: center !important;
    color: rgb(51, 59, 82) !important;
} */
</style>
