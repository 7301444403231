<template>
  <div class="audence">
    <input
      type="text"
      :value="chartOptions"
      style="width: 0px; height: 0px; opacity: 0"
    />
    <highcharts v-if="view" :options="chartOptions"> </highcharts>
    
    <div
      v-else
      class="image-container-off"
    >
      <img
        src="@/assets/image-off-audiencebenchmark.png"
        alt=""
        style="width: 100%; opacity: 0.5"
      />
      <div class="image-container-off__message">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOffGraphic }}
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapGetters,
} from "@isc/styleguide";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import stockInit from "highcharts/modules/stock";

stockInit(Highcharts);
exportingInit(Highcharts);

export default {
  name: "audence",
  props: {
    followers: {
      type: Array,
      default: [],
    },
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      view: false,
    };
  },
  computed: {
    ...mapGetters({
      msgImgOffGraphic: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
    chartOptions: function () {
      let audiencia_total = [];
      let audiencia_nueva = [];
      let nombreUsuario = [];
      let opcionRedSociales = 0;
      let opciones1 = "";
      let opciones2 = "";
      let color1 = "";
      let color2 = "";
      for (const item of this.followers) {
        audiencia_total.push(item.audiencia_total);
        audiencia_nueva.push(item.audiencia_nueva);
        nombreUsuario.push(item.nomUsuarioRedsocial);
      }
      if (this.followers.length > 0) {
        opcionRedSociales = this.followers[0]["id_red_social"];
        if (opcionRedSociales == 1) {
          opciones1 = "Fans";
          opciones2 = "New Fans";
          color1 = "rgba(24, 119, 242, 1)";
          color2 = "rgba(24, 119, 242, 0.3)";
        } else if (opcionRedSociales == 2) {
          opciones1 = "Followers";
          opciones2 = "New Followers";
          color1 = "rgba(24, 119, 242, 1)";
          color2 = "rgba(24, 119, 242, 0.3)";
        } else if (opcionRedSociales == 3) {
          opciones1 = "Followers";
          opciones2 = "New Followers";
          color1 = "rgba(255, 105, 161, 1)";
          color2 = "rgba(255, 105, 161, 0.3)";
        } else if (opcionRedSociales == 4) {
          opciones1 = "Subscribers";
          opciones2 = "New Subscribers";
          color1 = "rgba(255, 101, 100, 1)";
          color2 = "rgba(255, 101, 100, 0.3)";
        } else if (opcionRedSociales == 5) {
          opciones1 = "Followers";
          opciones2 = "New Followers";
          color1 = "rgba(0, 119, 181 , 1)";
          color2 = "rgba(77, 161, 205, 0.3)";
        }
      } else {
        opcionRedSociales = 0;
      }

      let chartOption = {
        chart: {
          type: "bar",
          innerHeight: 120,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },

        xAxis: {
          categories: nombreUsuario,
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
          allowDecimals: false,
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
          },
        },
        series: [
          {
            name: opciones1,
            data: audiencia_total,
            color: color1,
          },
          {
            name: opciones2,
            data: audiencia_nueva,
            color: color2,
          },
        ],
      };
      this.validate(audiencia_nueva, audiencia_total);

      return chartOption;
    },
  },
  methods: {
    validate(newaudience, totalaudience) {
      this.view = false;
      this.view = totalaudience.some((item) => {
        if (item > 0) {
          return true;
        }
      });
      if (!this.view) {
        this.view = newaudience.some((item) => {
          if (item > 0) {
            return true;
          }
        });
      }
    },
  },
  mounted() {},
};
</script>
