var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("input", {
        staticStyle: { width: "0px", height: "0px", opacity: "0" },
        attrs: { type: "text" },
        domProps: { value: _vm.chartOptions }
      }),
      _vm.view == true
        ? _c("highcharts", { attrs: { options: _vm.chartOptions } })
        : _c("div", { staticClass: "image-container-off" }, [
            _c("img", {
              staticClass: "opacity-50",
              attrs: {
                src: require("@/assets/image-off-postbenchmark.png"),
                alt: ""
              }
            }),
            _c("div", { staticClass: "image-container-off__message" }, [
              _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
              _vm._v(" " + _vm._s(_vm.msgImgOffGraphic) + " ")
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }