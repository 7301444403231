<template>
  <div class="TopContent">
    <div
      v-for="(item, index) in objs"
      :key="item + index"
      class="container-top-content"
    >
      <div v-if="item.posts.length == 0" class="container-img-default">
        <div class="container-title-top-content">
          {{ item.nameaccount }} No Found
        </div>
        <!-- <img
          v-if="benchsel === 'facebook'"
          src="../../assets/imgDefault/facebook.png"
          class="img-default"
        />
        <img
          v-if="benchsel === 'instagram'"
          src="../../assets/imgDefault/instagram.png"
          class="img-default"
        />
        <img
          v-if="benchsel === 'twitter'"
          src="../../assets/imgDefault/twitter.png"
          class="img-default"
        /> -->
      </div>

      <div v-if="item.posts.length > 0">
        <div class="container-title-top-content">
          {{ item.nameaccount }}
        </div>
        <div
          v-for="(_item, key) in item.posts"
          :key="_item.id_post_red_social + key"
          class="container-post"
        >
          <div
            :class="[
              'container-header',
              _item.id_red_social == '1' ? 'fbbg' : '',
              _item.id_red_social == '2' ? 'twbg' : '',
              _item.id_red_social == '3' ? 'inbg' : '',
              _item.id_red_social == '4' ? 'ytbg' : '',
              _item.id_red_social == '5' ? 'libg' : '',
            ]"
            style="background-color: rgb(0, 165, 255)"
          >
            <div class="contaienr-header-interactions">
              <i
                v-if="_item.id_red_social == 1"
                class="fab fa-facebook fbco text-white"
              ></i>
              <i
                v-if="_item.id_red_social == 2"
                class="fab fa-twitter fbco text-white"
              ></i>
              <i
                v-if="_item.id_red_social == 3"
                class="fab fa-instagram fbco text-white"
              ></i>
              <i
                v-if="_item.id_red_social == 4"
                class="fab fa-youtube fbco text-white"
              ></i>
              <i
                v-if="_item.id_red_social == 5"
                class="fab fa-linkedin fbco text-white"
              ></i>
              <span class="text-white header-text-style">Interactions</span>
            </div>
            <div class="text-white container-header-value">
              {{
                (_item.interacciones.angry || 0) +
                (_item.interacciones.dislike || 0) +
                (_item.interacciones.like || 0) +
                (_item.interacciones.love || 0) +
                (_item.interacciones.retweet || 0) +
                (_item.interacciones.sad || 0) +
                (_item.interacciones.share || 0) +
                (_item.interacciones.wow || 0) +
                (_item.interacciones.haha || 0) +
                (_item.total_comentarios || 0)
              }}
            </div>
          </div>
          <div class="container-body">
            <div class="container-body-account">
              <div>
                <img :src="item.perfilimg" class="account-image-style" />
              </div>
              <div class="account-description-style">
                <div class="account-name-date-publication text-bold">
                  {{ item.nameaccount }}
                </div>
                <div class="account-name-date-publication">
                  {{ getStringDate(_item.fechapost) }}
                </div>
              </div>
            </div>
            <div class="account-label-style">
              <a
                target="_blank"
                :href="_item.url_Post"
                class="account-label-style"
                >{{ _item.postcontent }}
              </a>
            </div>
            <div v-if="(_item.images.length || '') > 0">
              <img
                onerror="this.onerror=null;this.src='https://cdn4.iconfinder.com/data/icons/solid-part-6/128/image_icon-128.png';"
                :src="_item.images"
                alt=""
                class="images-top"
              />
            </div>
            <!---->
          </div>
          <div class="container-footer">
            <div>
              <!-----FACEBOOK------->
              <div v-if="_item.id_red_social == 1" class="container-footer-row">
                <div>
                  <i class="far fa-share-square text-gray"></i>
                  <span class="footer-interaction-style">Shares</span>
                </div>
                <div class="footer-interaction-style">
                  {{ _item.interacciones.share }}
                </div>
              </div>
              <div v-if="_item.id_red_social == 1" class="container-footer-row">
                <div>
                  <i class="far fa-heart text-gray"></i>
                  <span class="footer-interaction-style">Love</span>
                </div>
                <div class="footer-interaction-style">
                  {{ _item.interacciones.love }}
                </div>
              </div>
              <div v-if="_item.id_red_social == 1" class="container-footer-row">
                <div>
                  <i class="far fa-grin-squint text-gray"></i>
                  <span class="footer-interaction-style">Haha</span>
                </div>
                <div class="footer-interaction-style">
                  {{ _item.interacciones.haha }}
                </div>
              </div>
              <div v-if="_item.id_red_social == 1" class="container-footer-row">
                <div>
                  <i class="far fa-thumbs-up text-gray"></i>
                  <span class="footer-interaction-style">Like</span>
                </div>
                <div class="footer-interaction-style">
                  {{ _item.interacciones.like }}
                </div>
              </div>
              <div v-if="_item.id_red_social == 1" class="container-footer-row">
                <div>
                  <i class="far fa-frown text-gray"></i>
                  <span class="footer-interaction-style">Sad</span>
                </div>
                <div class="footer-interaction-style">
                  {{ _item.interacciones.sad }}
                </div>
              </div>
              <div v-if="_item.id_red_social == 1" class="container-footer-row">
                <div>
                  <i class="far fa-angry text-gray"></i>
                  <span class="footer-interaction-style">Angry</span>
                </div>
                <div class="footer-interaction-style">
                  {{ _item.interacciones.angry }}
                </div>
              </div>
              <div v-if="_item.id_red_social == 1" class="container-footer-row">
                <div>
                  <i class="far fa-surprise text-gray"></i>
                  <span class="footer-interaction-style">Wow</span>
                </div>
                <div class="footer-interaction-style">
                  {{ _item.interacciones.wow }}
                </div>
              </div>
              <div v-if="_item.id_red_social == 1" class="container-footer-row">
                <div>
                  <i class="far fa-comments text-gray"></i>
                  <span class="footer-interaction-style">Comments</span>
                </div>
                <div class="footer-interaction-style">
                  {{
                    _item.total_comentarios == 0
                      ? _item.comentarios
                      : _item.total_comentarios
                  }}
                </div>
              </div>
              <!-----FACEBOOK------->
              <!-----TWITTER------->
              <div v-if="_item.id_red_social == 2" class="container-footer-row">
                <div>
                  <i class="far fa-heart text-gray"></i>
                  <span class="footer-interaction-style">Favorites</span>
                </div>
                <div class="footer-interaction-style">
                  {{ _item.interacciones.like }}
                </div>
              </div>
              <div v-if="_item.id_red_social == 2" class="container-footer-row">
                <div>
                  <i class="fa fa-retweet text-gray"></i>
                  <span class="footer-interaction-style">Retweet</span>
                </div>
                <div class="footer-interaction-style">
                  {{ _item.interacciones.retweet }}
                </div>
              </div>
              <div v-if="_item.id_red_social == 2" class="container-footer-row">
                <div>
                  <i class="fa fa-comments text-gray"></i>
                  <span class="footer-interaction-style">Comments</span>
                </div>
                <div class="footer-interaction-style">
                  {{
                    _item.total_comentarios == 0
                      ? _item.comentarios
                      : _item.total_comentarios
                  }}
                </div>
              </div>
              <!-----TWITTER------->
              <!-----INSTAGRAM------->
              <div v-if="_item.id_red_social == 3" class="container-footer-row">
                <div>
                  <i class="far fa-heart text-gray"></i>
                  <span class="footer-interaction-style">Favorites</span>
                </div>
                <div class="footer-interaction-style">
                  {{ _item.interacciones.like }}
                </div>
              </div>
              <div v-if="_item.id_red_social == 3" class="container-footer-row">
                <div>
                  <i class="fa fa-comments text-gray"></i>
                  <span class="footer-interaction-style">Comments</span>
                </div>
                <div class="footer-interaction-style">
                  {{
                    _item.total_comentarios == 0
                      ? _item.comentarios
                      : _item.total_comentarios
                  }}
                </div>
              </div>
              <!-----INSTAGRAM------->
              <!-----YOUTUBE------->
              <div v-if="_item.id_red_social == 4" class="container-footer-row">
                <div>
                  <i class="far fa-thumbs-up text-gray"></i>
                  <span class="footer-interaction-style">Likes</span>
                </div>
                <div class="footer-interaction-style">
                  {{ _item.interacciones.like }}
                </div>
              </div>
              <div v-if="_item.id_red_social == 4" class="container-footer-row">
                <div>
                  <i class="fa fa-thumbs-down text-gray"></i>
                  <span class="footer-interaction-style">Dislikes</span>
                </div>
                <div class="footer-interaction-style">
                  {{ _item.interacciones.dislike }}
                </div>
              </div>
              <div v-if="_item.id_red_social == 4" class="container-footer-row">
                <div>
                  <i class="fa fa-comments text-gray"></i>
                  <span class="footer-interaction-style">Comments</span>
                </div>
                <div class="footer-interaction-style">
                  {{
                    _item.total_comentarios == 0
                      ? _item.comentarios
                      : _item.total_comentarios
                  }}
                </div>
              </div>
              <!-----YOUTUBE------->
              <!-----LINIKEDIN------->
              <div v-if="_item.id_red_social == 5" class="container-footer-row">
                <div>
                  <i class="far fa-thumbs-up text-gray"></i>
                  <span class="footer-interaction-style">Likes</span>
                </div>
                <div class="footer-interaction-style">
                  {{ _item.interacciones.like }}
                </div>
              </div>
              <div v-if="_item.id_red_social == 5" class="container-footer-row">
                <div>
                  <i class="fa fa-glass-cheers text-gray"></i>
                  <span class="footer-interaction-style">Celebrate</span>
                </div>
                <div class="footer-interaction-style">
                  {{ _item.interacciones.haha }}
                </div>
              </div>
              <div v-if="_item.id_red_social == 5" class="container-footer-row">
                <div>
                  <i class="far fa-surprise text-gray"></i>
                  <span class="footer-interaction-style">Curious</span>
                </div>
                <div class="footer-interaction-style">
                  {{ _item.interacciones.wow }}
                </div>
              </div>
              <div v-if="_item.id_red_social == 5" class="container-footer-row">
                <div>
                  <i class="far fa-heart text-gray"></i>
                  <span class="footer-interaction-style">Love</span>
                </div>
                <div class="footer-interaction-style">
                  {{ _item.interacciones.love }}
                </div>
              </div>
              <div v-if="_item.id_red_social == 5" class="container-footer-row">
                <div>
                  <i class="far fa-lightbulb text-gray"></i>
                  <span class="footer-interaction-style">Insightful</span>
                </div>
                <div class="footer-interaction-style">
                  {{ _item.interacciones.sad }}
                </div>
              </div>
              <div v-if="_item.id_red_social == 5" class="container-footer-row">
                <div>
                  <i class="fa fa-hand-holding-heart text-gray"></i>
                  <span class="footer-interaction-style">Support</span>
                </div>
                <div class="footer-interaction-style">
                  {{ _item.interacciones.share }}
                </div>
              </div>
              <div v-if="_item.id_red_social == 5" class="container-footer-row">
                <div>
                  <i class="fa fa-comments text-gray"></i>
                  <span class="footer-interaction-style">Comments</span>
                </div>
                <div class="footer-interaction-style">
                  {{
                    _item.total_comentarios == 0
                      ? _item.comentarios
                      : _item.total_comentarios
                  }}
                </div>
              </div>
              <!-----LINIKEDIN------->
            </div>
            <!---->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Helpers } from "@isc/styleguide";
export default {
  name: "TopContent",
  props: {
    followers: Array,
    postsendTopcontent: Array,
    benchsel: String,
    countryZone: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      objs: Object,
    };
  },
  methods: {
    async returnTopContents(postsendTopcontent) {
      let result = [];
      if (postsendTopcontent.length > 0) {
        for (const [index, item] of postsendTopcontent.entries()) {
          let arr = {
            nameaccount: "",
            nomCuentaRedSocial: "",
            perfilimg: "",
            posts: [],
          };
          if (item.length > 0) {
            // order for amount of interactions and comments
            let temp = item.sort((a, b) => {
              let total_Interactions_a =
                (a.interacciones.angry || 0) +
                (a.interacciones.dislike || 0) +
                (a.interacciones.like || 0) +
                (a.interacciones.love || 0) +
                (a.interacciones.retweet || 0) +
                (a.interacciones.sad || 0) +
                (a.interacciones.share || 0) +
                (a.interacciones.wow || 0) +
                (a.interacciones.haha || 0) +
                (a.total_comentarios || 0);
              let total_Interactions_b =
                (b.interacciones.angry || 0) +
                (b.interacciones.dislike || 0) +
                (b.interacciones.like || 0) +
                (b.interacciones.love || 0) +
                (b.interacciones.retweet || 0) +
                (b.interacciones.sad || 0) +
                (b.interacciones.share || 0) +
                (b.interacciones.wow || 0) +
                (b.interacciones.haha || 0) +
                (a.total_comentarios || 0);
              if (total_Interactions_a < total_Interactions_b) {
                return 1;
              }
              if (total_Interactions_a > total_Interactions_b) {
                return -1;
              }
              return 0;
            });
            //* this logic works depending of amount of posts.
            // If posts > 3 = limit = 2, but posts = 1 or 2 then check this condition
            //temp.length <= 3 && temp.length >= 1 ? temp.length - 1 : 0;
            let limit =
              temp.length > 3
                ? 2
                : temp.length == 0
                ? 0
                : temp.length <= 3 && temp.length >= 1
                ? temp.length - 1
                : 0;

            for (let index = 0; index <= limit; index++) {
              let account = this.followers.find(
                (inp) =>
                  inp.nomCuentaRedSocial == temp[index].id_cuenta_red_social
              );
              if (account) {
                arr.nameaccount = account.nomUsuarioRedsocial;
                arr.nomCuentaRedSocial = account.nomCuentaRedSocial;
                arr.perfilimg = account.dscLogo;

                arr.posts.push({
                  comentarios: temp[index].comentarios.length || 0,
                  total_comentarios: temp[index].total_comentarios || 0,
                  interacciones: temp[index].interacciones,
                  postcontent: temp[index].post_content,
                  fechapost: temp[index].post_fecha,
                  images: temp[index].post_imagen || "",
                  id_red_social: temp[index].id_red_social,
                  id_post_red_social: temp[index].id_red_social,
                  url_Post: temp[index].url_Post.replace("}", ""),
                });
              }
            }
          } else {
            arr.nameaccount = this.followers[index].nomUsuarioRedsocial
          }
          result.push(arr);
        }
       // console.log("FINAL RESULT  ==> ", result);
        if (result.length != this.followers.length) {
          let resta = this.followers.length - result.length;
          for (let index = 0; index < resta; index++) {
            result.push({
              nameaccount: "",
              nomCuentaRedSocial: "",
              perfilimg: "",
              posts: [],
            });
          }
        }
      }
      this.objs = result;
      return result;
    },
    getStringDate(date) {
      return Helpers.transformStringDate(date,this.countryZone, "DD/MM/YYYY HH:mm:ss");
    },
  },
};
</script>

<style scoped>
.off-image-top-content {
  opacity: 0.2;
  margin: 3em 0;
}

.container-title-top-content {
  margin-bottom: 1em;
  padding-top: 2em;
  font-weight: bold;
}

.container-general-top-content {
  background-color: white;
}

.container-top-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 1.5%;
}

.container-post {
  float: left;
  width: 362px;
  height: fit-content;
  background-color: #ffffff;
  margin-right: 20px;
  border-radius: 6px;
  box-shadow: 0px 2px 3.9px 0.1px rgba(0, 0, 0, 0.15);
  /* margin: 2em 1em; */
}

.container-header {
  width: 362px;
  min-height: 55px;
  display: flex;
  background-color: #4777ee;
  justify-content: space-between;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.contaienr-header-interactions {
  padding: 0 1em;
}

.header-text-style {
  font-size: 15px;
  line-height: 3.5;
}

.container-header-value {
  font-size: 32px;
  font-weight: bold;
  padding: 0 1em;
  line-height: 1.7;
}

.container-body {
  padding: 1em 0;
}

.container-body-account {
  display: flex;
  padding: 0 1em;
}

.account-image-style {
  border-radius: 50%;
  width: 42px;
  height: 42px;
}

.account-label-style {
  text-decoration: none;
  color: #5d6b88;
  font-family: Oxygen;
  font-size: 15px;
  overflow-x: hidden;
  margin: 1em;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.account-description-style {
  padding: 0 1em;
}

.container-footer {
  padding-bottom: 1em;
}

.container-footer-row {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1em;
}

.footer-interaction-style {
  font-size: 13px;
  color: #5d6b88;
}

.text-white {
  color: white;
}

.text-gray {
  color: #5d6b88;
}

.text-bold {
  font-weight: bold;
}

.account-name-date-publication {
  font-size: 14px;
  color: #5d6b88;
}

.fbbg {
  background: #1778f2 !important;
}

.twbg {
  background: #1da1f2 !important;
}

.inbg {
  background: #ff69a1 !important;
}

.ytbg {
  background: #ff6564 !important;
}

.libg {
  background: #0073b1 !important;
}

.fa {
  padding-right: 10px;
}

.far {
  padding-right: 10px;
}

.fab {
  padding-right: 10px;
}

.container-title-top-content {
  width: 100%;
}

.images-top {
  max-height: 15rem;
  width: 100%;
}

.container-img-default {
  width: 97%;
}

.img-default {
  width: 100%;
  padding-top: 1rem;
  opacity: 0.5;
}
</style>
