import Router from 'vue-router'
import facebook from "./views/Facebook.vue"
import youtube from "./views/Youtube.vue"
import instagram from "./views/Instagram.vue"
import twitter from "./views/Twitter.vue"
import linkedin from "./views/Linkedin.vue"
export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [    
    {
      path: "/benchmark/facebook", 
      component: facebook,
    },
    {
      path: "/benchmark/youtube", 
      component: youtube,
    },
    {
      path: "/benchmark/instagram", 
      component: instagram,
    },
    
    {
      path: "/benchmark/twitter", 
      component: twitter,
    },
    {
      path: "/benchmark/linkedin", 
      component: linkedin,
    },
  ]
})
