<template>
  <div class="instagram" style="font-family: Oxygen;">
    <bread-crumb>
      <v-datepicker
        range
        v-model="rangeDate"
        class="mr-3"
        ref="datePicker"
        type="date"
        :clearable="false"
        range-separator=" - "
        format="DD/MM/YYYY"
        @change="changeDate"
        @open="openCalendar"
        :disabled-date="disabledAfter"
      />
    </bread-crumb>
    <BenchSelect
      benchsel="instagram"
      ref="selectIgComponent"
      :range-date="rangeDate"
      :opcRedSocial="3"
      descripction="https://www.instagram.com"
      validador="instagram.com"
    ></BenchSelect>
  </div>
</template>

<script>
import { BreadCrumb, Helpers } from "@isc/styleguide";
import BenchSelect from "@/components/shared/Select.vue";
import moment from "moment";
export default {
  name: "InstagramBench",
  components: {
    BreadCrumb,
    BenchSelect,
    moment,
  },
  data() {
    return {
      rangeDate: [
        moment()
          .subtract(15, "days")
          .startOf("day")
          .toDate(),
        moment().toDate(),
      ],
      prevRangeDate: [],
    };
  },
  methods: {
    async changeDate(date) {
      this.rangeDate[0] = date[0];
      this.rangeDate[1] = date[1];
      const validateRange = Helpers.validateRange(
        this.rangeDate[0],
        this.rangeDate[1]
      );
      if (!validateRange) {
        this.rangeDate = this.prevRangeDate;
        let message = "You can only search up to the last 30 days.";
        let title = "Error";
        Helpers.getToastAlert(
          this.$bvToast,
          message,
          title,
          null,
          "errorBvToast"
        );
      } else {
        await this.$refs.selectIgComponent.refreshDetalle(date[0], date[1]);
      }
    },
    openCalendar() {
      this.prevRangeDate = this.rangeDate;
    },
    disabledAfter(date) {
      return date > new Date();
    },
  },
};
</script>

<style></style>
