<template>
  <div>
    <input
      type="text"
      :value="chartOptions"
      style="width: 0px; height: 0px; opacity: 0"
    />
    <highcharts v-if="view == true" :options="chartOptions"></highcharts>
    <div
      v-else
      class="image-container-off"
    >
      <img
        class="opacity-50"
        src="@/assets/image-off-postbenchmark.png"
        alt=""
      />
      <div class="image-container-off__message">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOffGraphic }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import * as BenchmarkService from "@/services/benchmark.service";
import { Dayjs, mapGetters } from "@isc/styleguide";
var links = [];
export default {
  name: "postbyday",
  props: {
    followers: {
      type: Array,
      default: [],
    },
    returnTopContents: Array,
    timezoneId: {
      type: [Number, String],
    },
    countryZone: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      series: [],
      chartCategories: [],
      postall: [],
      categories: [],
      dateList: [],
      view: false,
    };
  },
  methods: {
    async obtainposts(rangeDate) {
      try {
        this.categories = this.getrange(rangeDate);
        let s = moment(rangeDate[0]).startOf("day");
        rangeDate[0] = s._d;
        // console.log("RANGEDATE",s._d)
        let series = [];
        let colors = ["#4777ee", "#efbe2c", "#6c6fbe", "#6caa6d", "#df5353"];
        let acumulate = 0;
        links = [];
        this.postsall = [];
        for (const item of this.followers) {
          let _postall = [];
          let data = [];
          let dataRequestParams = {
            idRedSocial: item.nomCuentaRedSocial,
            fecha_ini: rangeDate[0],
            fecha_fin: rangeDate[1],
            timezone_id: this.timezoneId,
          };
          let response = await BenchmarkService.searchPostsbyRedSocial(
            dataRequestParams
          );
          for (const it of response) {
            let postDate = Dayjs.utc(it.post_fecha)
              .tz(this.countryZone)
              .format();
            let numdia = Dayjs.utc(postDate)
              .tz(this.countryZone)
              .date();
            let hora = Number(
              Dayjs.utc(postDate)
                .tz(this.countryZone)
                .hour() +
                "." +
                Dayjs.utc(postDate)
                  .tz(this.countryZone)
                  .minute() +
                "" +
                Dayjs.utc(postDate)
                  .tz(this.countryZone)
                  .second() +
                "" +
                Dayjs.utc(postDate)
                  .tz(this.countryZone)
                  .millisecond()
            );
            let customminute =
              Dayjs.utc(postDate)
                .tz(this.countryZone)
                .minute() < 10
                ? "0" +
                  Dayjs.utc(postDate)
                    .tz(this.countryZone)
                    .minute()
                : Dayjs.utc(postDate)
                    .tz(this.countryZone)
                    .minute();
            let custom =
              Dayjs.utc(postDate)
                .tz(this.countryZone)
                .hour() +
              ":" +
              customminute;
            let indexfecha = this.dateList.indexOf(
              Dayjs.utc(postDate)
                .tz(this.countryZone)
                .format("DD-MM-YYYY")
            );
            _postall.push(it);
            links.push({
              dia: indexfecha,
              hora: hora,
              urlpost: it.url_Post.replace("}", ""),
              id: "graf" + it.id_post_red_social,
            });
            data.push({
              x: indexfecha,
              y: hora,
              customValue: `Day: ${numdia}, Hour: ${custom}`,
            });
          }
          series.push({
            name: item.nomUsuarioRedsocial,
            color: colors[acumulate],
            data: data,
          });
          acumulate += 1;
          this.postsall.push(_postall);
        }
        this.series = series;
      } catch (error) {}
    },
    getrange(rangeDate) {
      const formatDateInit = Dayjs.utc(rangeDate[0])
        .tz(this.countryZone)
        .format("YYYY-MM-DD");
      const formatDateEnd = Dayjs.utc(rangeDate[1])
        .tz(this.countryZone)
        .format("YYYY-MM-DD");
      try {
        let lastMonth = 0;
        let chartCategories = [];
        let dateList = [];

        for (
          var m = moment(formatDateInit);
          m.diff(formatDateEnd, "days") <= 0;
          m.add(1, "days")
        ) {
          const init = lastMonth !== 0 && lastMonth !== m.month();
          chartCategories.push(
            `${m.date()}${init ? "<br>" + m.format("MMMM") : ""}`
          );
          dateList.push(m.format("DD-MM-YYYY"));
          lastMonth = m.month();
        }
        //* range of days and chartCategories are according to range of dates
        this.dateList = dateList;
        this.chartCategories = chartCategories;

        return chartCategories;
      } catch (error) {}
    },
    validateView(series) {
      this.view = false;
      for (const item of series)
        if (!this.view) {
          if (item.data.length > 0) {
            this.view = true;
          }
        }
    },
    // no function uses
    /* formatSeriesPost: function() {
      let series = [{}, {}, {}, {}, {}];
      let seriesNames = [];
      let colors = ["#4777ee", "#efbe2c", "#6c6fbe", "#6caa6d", "#df5353"];
      let coordenadasPerfil0 = [];
      let coordenadasPerfil1 = [];
      let coordenadasPerfil2 = [];
      let coordenadasPerfil3 = [];
      let coordenadasPerfil4 = [];
      for (let i = 0; i < this.followers.length; i++) {
        seriesNames.push(this.followers[i].nomUsuarioRedsocial);
      }
      for (let i = 0; i < this.followers.length; i++) {
        let coordenadas = [];
        let dias = "";
        let dia = "";
        let hora = "";
        var fechas = this.followers[i].fec_creacion;
        var partdia = fechas.split("-");
        var partsOfStr = fechas.split(":");
        this.hora = partsOfStr[1];
        dias = partdia[2];

        dia = dias.substring(0, 2);
        coordenadas.push(parseInt(this.dia), parseInt(this.hora));
        switch (i) {
          case 0:
            coordenadasPerfil0 = coordenadas ? coordenadas : [];
            break;
          case 1:
            coordenadasPerfil1 = coordenadas ? coordenadas : [];
            break;
          case 2:
            coordenadasPerfil2 = coordenadas ? coordenadas : [];
            break;
          case 3:
            coordenadasPerfil3 = coordenadas ? coordenadas : [];
            break;
          case 4:
            coordenadasPerfil4 = coordenadas ? coordenadas : [];
            break;
          default:
            break;
        }
      }
      for (let i = 0; i < series.length; i++) {
        series[i].name = seriesNames[i] ? seriesNames[i] : "";
        series[i].color = colors[i];
        switch (i) {
          case 0:
            series[i].data = coordenadasPerfil0 ? coordenadasPerfil0 : [];
            break;
          case 1:
            series[i].data = coordenadasPerfil1 ? coordenadasPerfil1 : [];
            break;
          case 2:
            series[i].data = coordenadasPerfil2 ? coordenadasPerfil2 : [];
            break;
          case 3:
            series[i].data = coordenadasPerfil3 ? coordenadasPerfil3 : [];
            break;
          case 4:
            series[i].data = coordenadasPerfil4 ? coordenadasPerfil4 : [];
            break;
          default:
            break;
        }
      }
      return series;
    }, */
  },
  computed: {
    ...mapGetters({
      msgImgOffGraphic: "global/helper/getImageOffGraphic",
      titleImgOff: "global/helper/getTitleImgOff",
    }),
    chartOptions() {
      let obj = {
        chart: {
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          type: "scatter",
          zoomType: "xy",
          marginTop: 10,
          marginBottom: 80,
        },
        credits: {
          enabled: false,
        },
        exporting: { enabled: false },
        title: { text: null },
        xAxis: {
          title: {
            text: "Days",
          },
          min: 0,
          categories: [],
        },
        yAxis: {
          title: { text: "" },
          labels: {
            formatter: function() {
              return this.value + ":00";
            },
          },

          min: 0,
          max: 24,
          tickInterval: 1,
          minorGridLineWidth: 0,
          gridLineWidth: 0,
          alternateGridColor: null,
          plotBands: [
            {
              from: 0,
              to: 12,
              color: "rgba(68, 170, 213, 0.1)",
              label: {
                text: "Morning",
                style: { color: "#606060" },
              },
            },
            {
              from: 12,
              to: 18,
              color: "rgba(0,0,0,0)",
              label: {
                text: "Afternoon",
                style: { color: "#606060" },
              },
            },
            {
              from: 18,
              to: 24,
              color: "rgba(68, 170, 213, 0.1)",
              label: {
                text: "Night",
                style: { color: "#606060" },
              },
            },
          ],
        },
        tooltip: {
          pointFormat: "{point.customValue}",
        },
        legend: {
          align: "right",
          x: 10,
          verticalAlign: "bottom",
          y: 10,
          floating: true,
          borderWidth: 0,
          shadow: false,
        },
        plotOptions: {
          scatter: {
            marker: {
              radius: 5,
              states: {
                hover: { enabled: true, lineColor: "rgb(100,100,100)" },
              },
            },
            states: {
              hover: { marker: { enabled: false } },
            },
          },
          series: {
            point: {
              events: {
                click: function(event) {
                  const dataOption = event.point.options;
                  const resp = links.find(
                    (it) => it.dia == dataOption.x && it.hora == dataOption.y
                  );
                  let temp = resp.urlpost || "";
                  if (temp.length > 0) window.open(resp.urlpost);
                },
              },
            },
            cursor: "pointer",
          },
        },
        series: [],
      };
      this.validateView(this.series);
      //* Setting graph  x and y coordinates.
      obj.xAxis.categories = this.categories;
      obj.xAxis.max = this.categories.length - 1;
      obj.series = this.series;

      this.$emit("update-topcontent", this.postsall);

      return obj;
    },
  },
};
</script>

<style></style>
