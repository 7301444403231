var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "instagram", staticStyle: { "font-family": "Oxygen" } },
    [
      _c(
        "bread-crumb",
        [
          _c("v-datepicker", {
            ref: "datePicker",
            staticClass: "mr-3",
            attrs: {
              range: "",
              type: "date",
              clearable: false,
              "range-separator": " - ",
              format: "DD/MM/YYYY",
              "disabled-date": _vm.disabledAfter
            },
            on: { change: _vm.changeDate, open: _vm.openCalendar },
            model: {
              value: _vm.rangeDate,
              callback: function($$v) {
                _vm.rangeDate = $$v
              },
              expression: "rangeDate"
            }
          })
        ],
        1
      ),
      _c("BenchSelect", {
        ref: "selectIgComponent",
        attrs: {
          benchsel: "instagram",
          "range-date": _vm.rangeDate,
          opcRedSocial: 3,
          descripction: "https://www.instagram.com",
          validador: "instagram.com"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }