var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-content-box" },
    [
      _vm.visibilityLoader === true ? _c("loader-custom") : _vm._e(),
      _vm.perfiles && _vm.perfiles.length > 0
        ? _c("div", [
            _c("div", [
              _c(
                "div",
                { staticClass: "mt-4 mb-3 pl-1" },
                [
                  _c(
                    "span",
                    { staticClass: "text-lg text-secondary font-weight-bold" },
                    [_vm._v(" Select profiles to compare ")]
                  ),
                  _c("i", {
                    staticClass: "fa fa-info-circle text-skygray text-lsm",
                    attrs: { id: "general-info" }
                  }),
                  _c("cp-tooltip-white-custom", {
                    attrs: {
                      target: "general-info",
                      message: "Update: Every 24 hours",
                      "padding-container": "30px"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "m-0 pl-0 pr-0 row" }, [
                _c(
                  "div",
                  { staticClass: "row col-12 pr-0 pl-0 w-100" },
                  [
                    _vm._l(_vm.Followers, function(item, index) {
                      return _c(
                        "div",
                        { key: item + index, staticClass: "data-s-3 col-2" },
                        [
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "m-0 row justify-content-center data-s-3"
                              },
                              [
                                _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "place-profile-image" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fas fa-times icono-profile",
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.deleteTask(index)
                                          }
                                        }
                                      }),
                                      _c("b-img", {
                                        staticClass: "imagen-nuevo-profile",
                                        attrs: { src: item.dscLogo },
                                        on: { error: _vm.errorUrlImage }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "texto text-xs text-color-text text-center"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.nomUsuarioRedsocial) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          ])
                        ]
                      )
                    }),
                    _vm._l(5 - _vm.Followers.length, function(i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "data-s-3 col-2 pl-2 pr-2" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "place-profile place-profile-box p-4 d-flex align-item",
                              on: {
                                click: function($event) {
                                  _vm.viewProfile = !_vm.viewProfile
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "m-0 d-flex flex-column align-items-center justify-content-center"
                                },
                                [
                                  _c("i", {
                                    staticClass: "ico-profile mb-3",
                                    class: [
                                      "fab fa-" + _vm.benchsel,
                                      "i-" + _vm.benchsel
                                    ]
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-xxs text-color-text" },
                                    [_vm._v("Place Profile")]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c(
                          "b-button",
                          {
                            class: ["button-" + _vm.benchsel, "profile-button"],
                            on: { click: _vm.openModalAddProfile }
                          },
                          [
                            _c("i", { staticClass: "fas fa-plus text-xs" }),
                            _c("span", { staticClass: "text-xs ml-1" }, [
                              _vm._v("Add new profile")
                            ])
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            class: ["clear-button", "profile-button"],
                            attrs: {
                              block: "",
                              disabled: _vm.Followers.length === 0
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.deleteCompleted.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "far fa-trash-alt",
                              attrs: { "font-scale": "1" }
                            }),
                            _vm._v(" Clear ")
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ])
            ]),
            _c(
              "div",
              [
                _c(
                  "b-modal",
                  {
                    ref: "addProfile",
                    attrs: {
                      size: "lg",
                      title: "Add Profile",
                      "hide-footer": "",
                      id: "modalbenchadd",
                      "header-class": "header-modal-profile",
                      "no-close-on-backdrop": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "modal-title",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-items-center pt-5"
                                },
                                [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "mb-0 mt-0 text-lsm text-secondary"
                                    },
                                    [_vm._v("Add Profile")]
                                  ),
                                  _vm.benchsel == "youtube"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-secondary ml-1 cursor-pointer"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa fa-info-circle text-sm text-skygray",
                                            attrs: { id: "youtube1" }
                                          }),
                                          _c("cp-tooltip-white-custom", {
                                            attrs: {
                                              target: "youtube1",
                                              message:
                                                "Only accounts of this type should be registered www.YouTube.com/@....",
                                              "padding-container": "15px"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.benchsel == "youtube"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-secondary ml-1 cursor-pointer"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa fa-info-circle text-sm text-skygray",
                                            attrs: { id: "youtube2" }
                                          }),
                                          _c("cp-tooltip-white-custom", {
                                            attrs: {
                                              target: "youtube2",
                                              message:
                                                "Channel information will be displayed according to the permissions granted by the channel creator.",
                                              "padding-container": "10px 7px"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1083901768
                    ),
                    model: {
                      value: _vm.addProfile,
                      callback: function($$v) {
                        _vm.addProfile = $$v
                      },
                      expression: "addProfile"
                    }
                  },
                  [
                    _c("close-button", { on: { close: _vm.closeModal } }),
                    _c("p", { staticClass: "title-add-profile" }, [
                      _vm._v("Add Profile")
                    ]),
                    _c(
                      "div",
                      { staticClass: "separacion_detalle" },
                      [
                        _c("b-input", {
                          staticClass: "add-input text-xs text-secondary",
                          attrs: {
                            type: "text",
                            placeholder: _vm.descripction,
                            size: "sm",
                            required: "",
                            id: "desc-benchtem"
                          },
                          model: {
                            value: _vm.urlText,
                            callback: function($$v) {
                              _vm.urlText = $$v
                            },
                            expression: "urlText"
                          }
                        }),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.modal-multi-3",
                                modifiers: { "modal-multi-3": true }
                              }
                            ],
                            staticClass: "btn-grabar",
                            attrs: { variant: "primary", size: "sm" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.grabar.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v(" Add ")]
                        )
                      ],
                      1
                    ),
                    _vm.benchsel == "youtube"
                      ? _c(
                          "span",
                          [
                            _c("cp-warning-message", {
                              attrs: {
                                message: "You can only link Youtube channels."
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "b-modal",
                  {
                    ref: "viewProfile",
                    attrs: {
                      size: "lg",
                      title: "Social Network Profile",
                      id: "modalbenchselect",
                      scrollable: "",
                      "ok-only": "",
                      "no-close-on-backdrop": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "modal-header-close",
                          fn: function() {
                            return [_c("cp-icon-close-x")]
                          },
                          proxy: true
                        },
                        {
                          key: "modal-title",
                          fn: function() {
                            return [
                              _c("h4", [_vm._v("Social Network Profile")])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3872420198
                    ),
                    model: {
                      value: _vm.viewProfile,
                      callback: function($$v) {
                        _vm.viewProfile = $$v
                      },
                      expression: "viewProfile"
                    }
                  },
                  [
                    _c(
                      "b-row",
                      { staticClass: "text-center mt-3" },
                      _vm._l(_vm.perfilesview, function(item, key) {
                        return _c(
                          "b-col",
                          {
                            key: item + key,
                            attrs: { md: "3" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.detalle(item.nomCuentaRedSocial)
                              }
                            }
                          },
                          [
                            _c(
                              "b-overlay",
                              {
                                staticClass: "d-inline-block mb-2 mt-2",
                                attrs: { show: _vm.show }
                              },
                              [
                                _c("b-img", {
                                  attrs: {
                                    fluid: "",
                                    src: item.dscLogo,
                                    alt: "Image 1"
                                  },
                                  on: { error: _vm.errorUrlImage }
                                })
                              ],
                              1
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "mb-3",
                                staticStyle: {
                                  "font-family": "Oxygen",
                                  "font-size": "12px",
                                  "font-weight": "bold"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(item.nomUsuarioRedsocial) + " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.detallesOpc,
                    expression: "detallesOpc"
                  }
                ],
                attrs: { id: "detallesOpc" }
              },
              [
                _vm.idCondRedSocial === 1
                  ? _c(
                      "div",
                      { staticClass: "separacion" },
                      [
                        _c(
                          "b-row",
                          {
                            staticClass: "row",
                            attrs: { "align-h": "around" }
                          },
                          [
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" Page Fans ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.audiencia_total
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" New Fans ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.audiencia_nueva >
                                                              0
                                                              ? item.audiencia_nueva
                                                              : 0
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" Comments ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.totalcomments ==
                                                              0
                                                              ? item.comments
                                                              : item.totalcomments
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _c("b-row", { staticClass: "separacion_detalle" }, [
                          _c("div", { staticClass: "col-4" }, [
                            _c(
                              "div",
                              { staticClass: "benchmark-report-container" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "card-container w-100",
                                    attrs: { "data-s-7": "" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-weight-bold mb-2 text-secondary text-lsm"
                                      },
                                      [_vm._v(" Shares ")]
                                    ),
                                    _vm._l(_vm.Followers, function(item, key) {
                                      return _c(
                                        "div",
                                        { key: item + key, staticClass: "row" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-6" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-color-text text-xs"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.nomUsuarioRedsocial
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col-md-6 text-right"
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-color-text text-xs font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.numFormatter(
                                                          item.shares
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "col-4" }, [
                            _c(
                              "div",
                              { staticClass: "benchmark-report-container" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "card-container w-100",
                                    attrs: { "data-s-7": "" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-weight-bold mb-2 text-secondary text-lsm"
                                      },
                                      [_vm._v(" Likes ")]
                                    ),
                                    _vm._l(_vm.Followers, function(item, key) {
                                      return _c(
                                        "div",
                                        { key: item + key, staticClass: "row" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-6" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-color-text text-xs"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.nomUsuarioRedsocial
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col-md-6 text-right"
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-color-text text-xs font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.numFormatter(
                                                          item.likes
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "col-4" })
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.idCondRedSocial === 2
                  ? _c(
                      "div",
                      { staticClass: "separacion" },
                      [
                        _c(
                          "b-row",
                          {
                            staticClass: "row",
                            attrs: { "align-h": "around" }
                          },
                          [
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" Followers ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.audiencia_total
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" New Followers ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.audiencia_nueva
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" Retweets ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.retweets
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _c(
                          "b-row",
                          {
                            staticClass: "separacion_detalle",
                            attrs: { "align-h": "around" }
                          },
                          [
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" Favorites ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.likes
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" Tweets ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.totalPost
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "col-4" })
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.idCondRedSocial === 3
                  ? _c(
                      "div",
                      { staticClass: "separacion" },
                      [
                        _c(
                          "b-row",
                          {
                            staticClass: "row",
                            attrs: { "align-h": "around" }
                          },
                          [
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" Followers ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.audiencia_total
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" New Followers ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.audiencia_nueva
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" Comments ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.totalcomments ==
                                                              0
                                                              ? item.comments
                                                              : item.totalcomments
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _c(
                          "b-row",
                          {
                            staticClass: "separacion_detalle",
                            attrs: { "align-h": "around" }
                          },
                          [
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" Likes ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.likes
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "col-4" }),
                            _c("div", { staticClass: "col-4" })
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.idCondRedSocial === 4
                  ? _c(
                      "div",
                      { staticClass: "separacion" },
                      [
                        _c(
                          "b-row",
                          {
                            staticClass: "row",
                            attrs: { "align-h": "around" }
                          },
                          [
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" Subscribers ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.audiencia_total
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" New Subscribers ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.audiencia_nueva
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" Comments ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.totalcomments ==
                                                              0
                                                              ? item.comments
                                                              : item.totalcomments
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _c(
                          "b-row",
                          {
                            staticClass: "separacion_detalle",
                            attrs: { "align-h": "around" }
                          },
                          [
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" Likes ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.likes
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" Dislikes ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.dislikes
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" Videos ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.countVideos
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.idCondRedSocial === 5
                  ? _c(
                      "div",
                      { staticClass: "separacion" },
                      [
                        _c(
                          "b-row",
                          {
                            staticClass: "row",
                            attrs: { "align-h": "around" }
                          },
                          [
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" Page Followers ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.audiencia_total
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" Page New Followers ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.audiencia_nueva >
                                                              0
                                                              ? item.audiencia_nueva
                                                              : 0
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "benchmark-report-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-container w-100",
                                      attrs: { "data-s-7": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold mb-2 text-secondary text-lsm"
                                        },
                                        [_vm._v(" Comments ")]
                                      ),
                                      _vm._l(_vm.Followers, function(
                                        item,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: item + key,
                                            staticClass: "row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.nomUsuarioRedsocial
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-color-text text-xs font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.numFormatter(
                                                            item.comments
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _c("b-row", { staticClass: "separacion_detalle" }, [
                          _c("div", { staticClass: "col-4" }, [
                            _c(
                              "div",
                              { staticClass: "benchmark-report-container" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "card-container w-100",
                                    attrs: { "data-s-7": "" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-weight-bold mb-2 text-secondary text-lsm"
                                      },
                                      [_vm._v(" Likes ")]
                                    ),
                                    _vm._l(_vm.Followers, function(item, key) {
                                      return _c(
                                        "div",
                                        { key: item + key, staticClass: "row" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-6" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-color-text text-xs"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.nomUsuarioRedsocial
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-6 text-center"
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-color-text text-xs font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.numFormatter(
                                                          item.likes
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "col-4" }),
                          _c("div", { staticClass: "col-4" })
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "tabs_graf" },
                  [
                    _c(
                      "b-card",
                      { attrs: { "no-body": "" } },
                      [
                        _c(
                          "b-tabs",
                          {
                            attrs: {
                              "content-class": "mt-3",
                              fill: "",
                              card: ""
                            }
                          },
                          [
                            _c(
                              "b-tab",
                              { attrs: { title: "Audience" } },
                              [
                                _c("h4", { staticClass: "tstabs" }, [
                                  _vm._v(" Audience "),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip"
                                      }
                                    ],
                                    staticClass: "fa fa-info-circle tlt-ico",
                                    attrs: {
                                      title:
                                        "Metrics are obtained every 24 hours."
                                    }
                                  })
                                ]),
                                _c("Audence", {
                                  attrs: { followers: this.Followers }
                                })
                              ],
                              1
                            ),
                            _vm.idCondRedSocial != 5
                              ? _c(
                                  "b-tab",
                                  { attrs: { title: "Post" } },
                                  [
                                    _c("h4", { staticClass: "tstabs" }, [
                                      _vm._v(" Post by day "),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip"
                                          }
                                        ],
                                        staticClass:
                                          "fa fa-info-circle tlt-ico",
                                        attrs: {
                                          title:
                                            "Constant monitoring of the metrics of the last 25 posts is maintained"
                                        }
                                      })
                                    ]),
                                    _c("Post", {
                                      ref: "Posts",
                                      attrs: {
                                        followers: this.Followers,
                                        "timezone-id": _vm.timeZoneId,
                                        countryZone: _vm.strCountryZone
                                      },
                                      on: {
                                        "update-topcontent": function($event) {
                                          return _vm.updateTopContent($event)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.idCondRedSocial != 5
                              ? _c(
                                  "b-tab",
                                  { attrs: { title: "Top Content" } },
                                  [
                                    _c("TopContent", {
                                      ref: "topcontent",
                                      staticClass: "topcon",
                                      attrs: {
                                        postsendTopcontent:
                                          _vm.postsendTopcontent,
                                        followers: this.Followers,
                                        benchsel: _vm.benchsel,
                                        countryZone: _vm.strCountryZone
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.idCondRedSocial != 5
                              ? _c(
                                  "b-tab",
                                  { attrs: { title: "Interactions" } },
                                  [
                                    _c("h4", { staticClass: "tstabs" }, [
                                      _vm._v(" Post Interactions "),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip"
                                          }
                                        ],
                                        staticClass:
                                          "fa fa-info-circle tlt-ico",
                                        attrs: {
                                          title:
                                            "Constant monitoring of the metrics of the last 25 posts is maintained"
                                        }
                                      })
                                    ]),
                                    _c("Interactions", {
                                      attrs: {
                                        optrss: _vm.opcRedSocial,
                                        followers: this.Followers
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ])
        : _c("cp-warning-info", {
            attrs: {
              "info-text":
                "You must add an account on Overview before using Benchmark."
            }
          }),
      _c(
        "div",
        [
          _c("IscModalAlerts", {
            attrs: {
              icon: _vm.icon,
              iconColor: _vm.iconColor,
              title: _vm.title,
              paragraph: _vm.paragraph,
              buttonMessage: _vm.buttonMessage,
              noCloseBackdrop: true,
              showBtn: _vm.showBtn,
              showMainIcon: _vm.showMainIcon,
              showBtnClose: _vm.showBtnClose
            },
            on: { sendResult: _vm.resultModalAlerts },
            model: {
              value: _vm.showAlert,
              callback: function($$v) {
                _vm.showAlert = $$v
              },
              expression: "showAlert"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }